// Please set this to the last input field before finishing submit button for the flow
const lastInputVariable = "email";

const states = {
    AL: 'Alabama',
    AK: 'Alaska',
    AR: 'Arkansas',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
};

const stateElectricProviders = {
    AK: [
        'Alaska Villages Electric Cooperative',
        'Chugach Electric Association',
        'Copper Valley Electric Association',
        'Golden Valley Electric Association',
        'Kodiak Electric Association',
        'Municipal Light & Power',
        'Other',
    ],
    AL: [
        'AECO (Alabama Electric Company)',
        'Alabama Power',
        'Alabama Rural Electric Association',
        'Baldwin EMC',
        'Central Alabama Electric Co-Op',
        'Cherokee Electric Cooperative',
        'City of Athens Utilities',
        'City of Bessemer',
        'City of Bessemer Electric and Water Service',
        'Covington Electric Cooperative',
        'Huntsville Utilities',
        'North Alabama Electric Coop',
        'PowerSouth Energy Cooperative AL Inc.',
        'Riviera Utilities',
        'Sand Mountain Electric Co-Op',
        'Sheffield Utilities',
        'Srd Electrical Services',
        'Tennessee Valley Authority',
        'Wiregrass Electric Cooperative',
        'Other',
    ],
    AR: [
        'Arkansas Valley Electric Cooperative',
        'C and L Electric Co-Op Corporation',
        'Carroll Electric Cooperative Corporation',
        'Entergy',
        'First Electric Cooperative',
        'North Arkansas Electric Cooperative',
        'North Little Rock Electric',
        'Ozarks Electric Cooperative',
        'Paragould Light Water and Cable (PLWC)',
        'Petit Jean Electric Cooperative',
        'Spark Energy',
        'Other',
    ],
    AZ: [
        'Amanti Electric',
        'Arizona Public Service',
        'City of Mesa Utilities',
        'City of Safford Electric Department',
        'Duncan Valley Electric Cooperative',
        'Electrical District No. 2',
        'Electrical District No. 3',
        'Graham County Utilities',
        'Mojave Electric Co-op',
        'Navopache Electric Cooperative',
        'Salt River Project',
        'SRP/Salt River Project',
        'Sulphur Springs Valley Electric Cooperative',
        'Trico Electric Cooperative',
        'Tucson Electric Power',
        'Unisource',
        'Xcel Energy',
        'Other',
    ],
    CA: [
        'Alameda Municipal Power',
        'Ambit Energy',
        'Anaheim Public Utilities',
        'Anza Electric Co-Op',
        'Azusa Light & Water',
        'Bear Valley Electric',
        'Burbank Water & Power',
        'California Public Utilities Commission',
        'City & County of San Francisco',
        'City of Banning',
        'City of Palo Alto Utilities',
        'City of Shasta Lake Electric Utility',
        'Colton Public Utilities and Public Service Department',
        'Colton Public Utilities Glendale Public Service Department',
        'El Cerrito Electric',
        'Glendale Water and Power',
        'Gridley Municipal Utilities',
        'Healdsburg Municipal Electric Department',
        'IGS Energy',
        'Imperial Irrigation District',
        'Lodi Electric Utility',
        'Los Angeles Department of Water and Power',
        'Marin Energy Authority',
        'Modesto Irrigation District',
        'Moreno Valley Electric Utility',
        'Mountain Utilities',
        'Pacific Gas & Electric',
        'PacifiCorp',
        'Pasadena Water & Power',
        'Redding Electric Utility (REU)',
        'Riverside Public Utilities',
        'Roseville Electric',
        'Sacramento Municipal Utility District',
        'San Diego Gas & Electric',
        'Sierra-Pacific Power',
        'Silicon Valley Power',
        'Southern California Edison',
        'Southern California Public Power Authority',
        'Surprise Valley Power',
        'Trinity PUD',
        'Turlock Irrigation District',
        'Valley Electric Association',
        'Vernon Light & Power',
        'Other',
    ],
    CO: [
        'Bedell Electric',
        'Black Hills Energy',
        'City of Fort Collins Utilities',
        'City of Loveland Water and Power',
        'City of Wauchula Electric',
        'Colorado Springs Utilities',
        'Delta-Montrose Electric Association',
        'Grand Valley Power',
        'GreyStone Power Corporation',
        'Gunnison County Electric Company',
        'Highline Electric Association',
        'Holy Cross Energy Company',
        'Intermountain Rural Electric Association',
        'Kit Carson Electric Cooperative',
        'La Plata Electric Association',
        'Lamar Light and Power',
        'Moon Lake Electric',
        'Morgan County Rural Electric Association',
        'Mountain View Electric Association',
        'Poudre Valley Rural Electric Association',
        'San Isabel Electric Association',
        'San Luis Valley Rural Electric',
        'San Miguel Power Association',
        'Southeast Colorado Power Association',
        'United Power Inc',
        'Xcel Energy',
        'Yampa Valley Electric Association',
        'Other',
    ],
    CT: [
        'Abest Power & Gas',
        'Ambit Energy',
        'Bozrah Light and Power',
        'Connecticut Light & Power',
        'Connecticut Natural Gas',
        'Groton Utilities',
        'Northeast Utilities',
        'United Illuminating',
        'Wallingford Electric',
        'Other',
    ],
    DC: ['Ambit Energy',
        'PEPCO',
        'Other'],
    DE: [
        'Calpine',
        'City of Dover Electric Department',
        'City of Milford Electric Department',
        'Delaware Electric Cooperative',
        'Delmarva Power',
        'NRG Energy',
        'Town of Middletown Utilities',
        'Town of Smyrna',
        'Other',
    ],
    FL: [
        'Duke Energy',
        'Florida Power & Light',
        'Florida Public Utility Company Palm Beach',
        'TECO',
        'Baugh Southeast Co-Op',
        'Choctawhatchee Electric Co-Op',
        'City of Leesburg',
        'City of Tallahassee Utilities',
        'City of Vero Beach',
        'Clay Electric Co-op',
        'Florida Keys Electric Cooperative',
        'Florida Municipal Power Agency',
        'Gainesville Regional Utilities',
        'Glades Electric Cooperative',
        'Gulf Power',
        'JEA',
        'Kissimmee Utility Authority',
        'Lake Worth Utilities',
        'Lakeland Electric',
        'LCEC',
        'Ocala Electric',
        'Okefenokee Rural Electric (OREMC)',
        'Orlando Utilities Commission',
        'Paige Electric',
        'Peace River Electric Cooperative',
        'Progress Energy Florida',
        'SECO Energy',
        'St. Petersburg Utility Department',
        'Talquin Electric Cooperative',
        'Tampa Electric',
        'West Florida Electric Cooperative',
        'Withlacoochee River Electric Cooperative',
        'Other',
    ],
    GA: [
        'Amicalola Electric Membership Corporation',
        'Blue Ridge Mountain EMC',
        'Charles E Hill Electric Co',
        'City of LaGrange Utilities',
        'City of Sylvester Utilities',
        'Cobb EMC',
        'Colquitt Electric Corporation',
        'Coweta Fayette EMC',
        'Diverse Power',
        'Fitzgerald Utilities',
        'Flint Energies',
        'Georgia EMC',
        'Georgia Power',
        'Habersham EMC',
        'Hart EMC',
        'Jackson EMC',
        'Rowell Electric Co',
        'Sawnee EMC',
        'Snapping Shoals EMC',
        'Southern Rivers Energy',
        'Tennessee Valley Authority',
        'Tri-State Electric',
        'Walton EMC',
        'Washington EMC',
        'Other',
    ],
    HI: [
        'Hawaiian Electric Industries',
        'Hawaiian Electric Company (HECO)',
        'Kauai Island Utility Cooperative (KIUC)',
        'Maui Electric Company',
        'Other',
    ],
    IA: [
        'Alliant Energy',
        'Chariton Valley Electric Cooperative',
        'City of Denver Electric (Iowa)',
        'Interstate Power and Light Company',
        'MidAmerican Energy',
        'Midland Power Co-Op',
        'Muscatine Power and Water',
        'Southwest Iowa Rural Electric Cooperative',
        'Tip Rural Electric Co-Op',
        'Other',
    ],
    ID: [
        'City of Rupert Electric',
        'City of Weiser',
        'Clearwater Power',
        'IDACORP',
        'Idaho Power',
        'Kootenai Electric Cooperative',
        'Northern Lights Electric Cooperative',
        'PacifiCorp (Rocky Mountain Power)',
        'Riverside Electric Co',
        'Other',
    ],
    IL: [
        'Ambit Energy',
        'Ameren',
        'Batavia Municipal Electric',
        'City of Princeton Electric',
        'City of St. Charles',
        'ComEd Electric Company',
        'Corn Belt Energy Corporation',
        'Gexpro Electric',
        'IGS Energy',
        'Jo-Carroll Energy',
        'Norris Electric Cooperative',
        'Red Bud Utilities Plant',
        'Springfield City Water Light & Power',
        'Tara Energy',
        'Village of Hanover Park Municipal Light and Power',
        'Other',
    ],
    IN: [
        'American Electric Power',
        'Duke Energy Indiana',
        'Garrett Electric Utilities',
        'Hendricks Power Cooperative',
        'Henry County REMC',
        'IGS Energy',
        'Indiana Michigan Power',
        'Indianapolis Power & Light',
        'Jackson County REMC',
        'Lebanon Utilities',
        'Meade Electric Company',
        'Mishawaka Utilities',
        'Northern Indiana Public Service Company',
        'South Central Indiana REMC',
        'Southeastern Indiana REMC',
        'Whitewater Valley REMC',
        'Other',
    ],
    KS: [
        'Bluestem Electric',
        'Caney Valley Elec Co-Op Association',
        'Kansas City Board of Public Utilities',
        'Kansas City Power & Light',
        'Midwest Energy',
        'Rolling Hills Electric Cooperative',
        'Westar Energy',
        'Other',
    ],
    KY: [
        'American Electric Power',
        'Berea Municipal Utilities',
        'Big Rivers Electric Corporation',
        'Blue Grass Energy',
        'Bowling Green Municipal Utilities',
        'Clark Energy Cooperative',
        'Duke Energy Kentucky',
        'Fleming-Mason Energy Cooperative',
        'Frankfort Plant Board',
        'Grayson Rural Electric Electric Cooperative',
        'Henderson Municipal Power and Light',
        'IGS Energy',
        'Kenergy Corp',
        'Kentucky Utilities',
        'Louisville Gas & Electric',
        'Nolin Rural Electric Cooperative',
        'Owen Electric',
        'Owensboro Municipal Utilities',
        'Pennyrile Rural Electric Co-Op',
        'Salt River Electric',
        'Shelby Energy Cooperative',
        'Tennessee Valley Authority',
        'West Kentucky Rural Electric Cooperative Corporation',
        'Other',
    ],
    LA: [
        'Beauregard Electric CO-OP',
        'CLECO',
        'Concordia Electric Co-Op',
        'DEMCO',
        'East-Central Iowa Rural Electric Cooperative',
        'Entergy',
        'Etheredge Electric Co',
        'Heartland Rural Electric Cooperative',
        'Jefferson Davis Electric Co-Op',
        'Lafayette Utilities System',
        'Pointe Coupee Electric Membership',
        'Puckett Electric',
        'SLECMO',
        'SWEPCO',
        'Town of Vinton Electric',
        'Washington – St. Tammany Electric Cooperative',
        'Other',
    ],
    MA: [
        'Ambit Energy',
        'Belmont Electric Light Department',
        'Berkshire Company',
        'Braintree Electric Light Department',
        'Chicopee Electric Light Department',
        'City of Fitchburg',
        'Hingham Light Department',
        'Holden Municipal Light Department',
        'Holyoke Gas and Electric',
        'Hudson Light and Power',
        'Ipswich Utility Department',
        'Mansfield Municipal Electric',
        'Marblehead Electric Light Department',
        'Massachusetts Electric',
        'Massachusetts Municipal Wholesale Electric Company (MMWEC)',
        'Middleborough Gas and Electric',
        'Nantucket Electric',
        'National Grid',
        'North Attleborough Electric Department',
        'Northeast Utilities',
        'Norwood Municipal Light and Power',
        'NSTAR',
        'Paxton Municipal Light Department',
        'Peabody Municipal Light Plant',
        'Reading Municipal Light Department',
        'Rowley Electric Light Department',
        'SELCO',
        'Sterling Municipal Light Department',
        'Taunton Municipal Lighting Plant (TMLP)',
        'Templeton Municipal Light Co',
        'Viridian',
        'Wakefield Municipal Gas and Light Department',
        'Western Massachusetts Electric',
        'Westfield Gas and Electric Department',
        'Other',
    ],
    MD: [
        'Allegheny Power',
        'Ambit Energy',
        'Baltimore Gas & Electric',
        'Choptank Electric Cooperative',
        'Constellation Energy',
        'Delmarva Power',
        'IGS Energy',
        'Kleppinger Electric Co',
        'PEPCO',
        'Potomoc Edison (FirstEnergy)',
        'Southern Maryland Electric Cooperative',
        'Other',
    ],
    ME: [
        'Bangor Hydro Electric',
        'Central Maine Power',
        'Eastern Maine Electric Cooperative',
        'Kennebunk Light and Power',
        'Maine Public Utilities Commission',
        'Other',
    ],
    MI: [
        'American Electric Power',
        'Consumers Energy',
        'DTE Energy (Detroit Edison)',
        'Excel Energy',
        'Holland Board of Public Works',
        'IGS Energy',
        'Indiana Michigan Power',
        'Lansing Board of Water & Light',
        'Midwest Energy Cooperative',
        'TC Electric',
        'Upper Peninsula Power Company',
        'Wakefield Municipal Gas and Light',
        'We Energies',
        'Wyandotte Municipal Services',
        'Other',
    ],
    MN: [
        'Alliant Energy',
        'Basin Electric Power Cooperative',
        'Connexus Energy',
        'Dakota Electric Association',
        'East Central Energy',
        'East Grand Forks Electric and Water',
        'East River Electric Power Co-op',
        'Goodhue County Co-Op Electric',
        'Great River Energy',
        'Hutchinson Utilities Commission',
        'ITC Midwest',
        'L&O Power Co-op',
        'Lake Country Power',
        'Marshall Municipal Utilities',
        'Mille Lacs Energy Co-Op',
        'Minnesota Power',
        'Minnesota Valley Electric Cooperative',
        'Minnkota Power Cooperative',
        'Missouri River Energy Services',
        'Moorhead City Public Services Department',
        'Otter Tail Power Company',
        'People',
        'Rochester Public Utilities',
        'Shakopee Public Utilities',
        'Tri-County Electric',
        'Xcel Energy',
        'Other',
    ],
    MO: [
        'Ameren',
        'Barton County Electric Cooperative',
        'Black River Electric Cooperative',
        'Boone Electric Cooperative',
        'Callaway Electric Cooperative',
        'Citizens Electric Corporation',
        'City of Waynesville Electric',
        'City Utilities of Springfield',
        'Coast Electric',
        'Cuivre River Electric',
        'Empire District Electric Company',
        'Gascosage Electric Co-Op',
        'Hannibal Board of Public Works',
        'Harrisonville Electric',
        'Howard Electric Cooperative',
        'Howell-Oregon Electric Co-Op',
        'Independence Power and Light',
        'Intercounty Electric Cooperative',
        'Kansas City Power & Light',
        'Laclede Electric Cooperative',
        'New-Mac Electric',
        'Nixa Utilities',
        'Ozark Border Electric Cooperative',
        'Platte-Clay Electric Cooperative',
        'SAC Osage Electric Co-Op',
        'Webster Electric Cooperative',
        'West Central Electric Cooperative',
        'White Valley River Valley Electric Cooperative',
        'Other',
    ],
    MS: [
        'Entergy',
        'Magnolia Electric',
        'Mississippi Power Company',
        'Southern Pine Electric Power Association',
        'Southwest Mississippi Electric Power Association',
        'Tennessee Valley Authority',
        'Tombigbee Electric Power Association',
        'Other',
    ],
    MT: [
        'Central Montana Electric Power Cooperative',
        'Flathead Electric Co-Op',
        'MDU',
        'Montana Electric Cooperatives’ Association',
        'Northwestern Energy',
        'Park Electric Co-Op',
        'Ravalli Electric Co-Op',
        'Vigilante Electric Co-Op',
        'Other',
    ],
    NC: [
        'Dominion North Carolina Power',
        'Duke Energy NC',
        'North Carolina Electric Membership Corp.',
        'Piedmont Electric',
        'Albemarle Electric Membership Corporation',
        'Ashley Smith Electric Co',
        'Blue Ridge Electrical Membership Corp.',
        'Brunswick EMC',
        'Cape Hatteras Electric Corporation',
        'Carteret-Craven Electric Cooperative',
        'Central Electric Membership Corporation',
        'City of Albemarle',
        'City of Kinston Electric',
        'City of Lexington Utilities',
        'City of New Bern Electric',
        'City of Shelby Utilities',
        'Dobbins Electric Co',
        'Edgecombe-Martin County EMC',
        'ElectriCities',
        'EnergyUnited Electric',
        'Farmville Utilities Department',
        'Four County Electric Membership',
        'French Broad Electric Membership Corporation',
        'Greenville Utilities Commission',
        'Halifax Electric Membership',
        'Haywood EMC',
        'High Point Electric Department',
        'Jones-Onslow EMC',
        'Lumbee River EMC',
        'Lumberton Power',
        'New River Light and Power',
        'Progress Energy Carolinas',
        'PWC Fayetteville',
        'Randolph Electric Membership Corp',
        'Roanoke Electric Co-Op',
        'Rutherford EMC',
        'Storm Electric',
        'Surry-Yadkin EMC',
        'Tideland EMC',
        'Town of Forest City Utilities',
        'Union Power Cooperative',
        'Wake Electric',
        'Washington Electric Utilities',
        'Wesco Electric',
        'Williams Electric Co',
        'WIlson Energy',
        'Wilson Utilities Department',
        'Other',
    ],
    ND: [
        'Basin Electric Power Cooperative',
        'Central Power Electric Cooperative',
        'MDU',
        'Minnkota Power Cooperative',
        'Otter Tail Power Company',
        'Upper Missouri G&T Cooperative',
        'Verendrye Electric Cooperative',
        'Xcel Energy',
        'Other',
    ],
    NE: [
        'ABC Electric Co',
        'Fairbury Light and Water',
        'Nebraska Public Power District',
        'Omaha Public Power District',
        'Other',
    ],
    NH: [
        'Granite State Electric',
        'Liberty Utilities',
        'National Grid',
        'Northeast Utilities',
        'Public Service of NH',
        'Unitil Corporation',
        'Other',
    ],
    NJ: [
        'Ambit Energy',
        'Asbury Park Electric Supply Co',
        'Atlantic City Electric',
        'FirstEnergy',
        'Gateway Energy Services',
        'Jersey Central Power and Light Company',
        'Milltown Boro Electric',
        'Northeast Utilities',
        'Orange & Rockland',
        'Powers Electric Company',
        'Public Service Electric and Gas Company (PSE&G)',
        'Sussex Rural Electric Cooperative',
        'Vineland Municipal Electric Utility',
        'Other',
    ],
    NM: [
        'Central New Mexico Electric Cooperative',
        'Columbus Electric Co-Op',
        'El Paso Electric',
        'Farmington Electric Utility System',
        'Jemez Mountains Electric Cooperative',
        'Kit Carson Electric',
        'Lea County Electric Cooperative',
        'Navajo Tribal Utility Authority',
        'Northern Rio Arriba Electric Cooperative',
        'PNM',
        'Public Service Company of New Mexico',
        'Other',
    ],
    NV: [
        'Lincoln County Power District Number 1',
        'Nevada Power',
        'NV Energy',
        'Overton Power District No 5',
        'Sierra Pacific Power',
        'Surprise Valley Electric',
        'Other',
    ],
    NY: [
        'Ambit Energy',
        'Azure Mountain Power Co',
        'Central Hudson Gas & Electric',
        'CH Energy Group',
        'Citizens Choice Energy Jamestown BPU',
        'Consolidated Edison Company of New York (Con Edison)',
        'Freeport Electric',
        'Green Island Power Authority',
        'IGS Energy',
        'Jamestown Board of Public Utilities',
        'Long Island Power Authority (LIPA)',
        'Massena Electric Department',
        'National Grid',
        'New York Power Authority (NYPA)',
        'New York State Electric & Gas (NYSEG)',
        'Ocean Electric',
        'Orange & Rockland',
        'Plattsburgh Municipal Lighting Department',
        'PSEG Long Island formerly LIPA',
        'Public Service Electric and Gas Company (PSE&G)',
        'Rochester Gas & Electric',
        'Salamanca Public Utilities',
        'Sherburne Municipal Electric',
        'Tupper Lake Municipal Electric Department',
        'Village of Akron Electric',
        'Village of Lake Placid',
        'Other',
    ],
    OH: [
        'American Electric Power',
        'Butler Rural Electric Cooperative',
        'Cincinnati Bell Energy',
        'Cleveland Electric Illuminating Company',
        'Consolidated Electric Cooperative',
        'Dayton Power & Light',
        'Duke Energy Ohio',
        'Firelands Electric Cooperative',
        'FirstEnergy',
        'Guernsey-Muskingum Electric Cooperative',
        'Hamilton Utility Services Department',
        'Hancock Wood Electric Cooperative',
        'Holmes-Wayne Electric Cooperative',
        'IGS Energy',
        'Jess Howard Electric',
        'Logan County Electric Cooperative',
        'Lorain Medina Rural Electric Cooperative',
        'Ohio Edison',
        'Paulding Putnam Electric Cooperative',
        'South Central Power Company',
        'The Energy Coop',
        'Toledo Edison',
        'Other',
    ],
    OK: [
        'American Electric Power',
        'Caddo Electric Cooperative',
        'City Of Waynoka Utility',
        'Compton Electric Company',
        'Cotton Electric Co-Op',
        'Kiamichi Electric Co-Op',
        'Oklahoma Gas & Electric',
        'Public Service Company of Oklahoma',
        'Other',
    ],
    OR: [
        'Ameren',
        'Ashland Electric',
        'Canby Electric',
        'City of Forest Grove Light and Power',
        'Columbia River Public Utility District',
        'Coos-Curry Electric Co-Op',
        'Emerald PUD',
        'Eugene Water & Electric Board',
        'Idaho Power',
        'PacifiCorp (Pacific Power)',
        'Portland General Electric',
        'Salem Electric',
        'Tillamook PUD',
        'Umatilla Electric Cooperative',
        'Wasco Electric',
        'West Oregon Electric Cooperative',
        'Other',
    ],
    PA: [
        'Adams Electric Cooperative',
        'Allegheny Power',
        'Ambit Energy',
        'Borough of Ellwood City',
        'Borough of Ellwood City Electric',
        'Citizens Electric of Lewisburg',
        'Claverack REC',
        'Duquesne Light',
        'FirstEnergy',
        'IGS Energy',
        'Lansdale Borough Electric',
        'Met-Ed',
        'PECO',
        'Penelec',
        'Penn Power',
        'Pike County Light & Power Company',
        'Powers Electric Company',
        'PPL',
        'Rural Valley Electric Co.',
        'Superior Plus Utility',
        'UGI Utilities Inc.',
        'Warren Electric Co-Op',
        'Wellsboro Electric Company',
        'West Penn Power',
        'Other',
    ],
    RI: ['Narragansett Electric',
        'National Grid',
        'Other'],
    SC: [
        'Berkeley Electric Cooperative',
        'Blue Ridge Electric Cooperative',
        'Broad River Electric Cooperative',
        'Central Electric Power Cooperative Inc.',
        'City of Rock Hill',
        'Duke Energy SC',
        'Horry Electric Co-Op',
        'Laurens Electric Cooperative',
        'Little River Electric Co-Op',
        'Newberry Electric Cooperative',
        'Palmetto Electric Cooperative',
        'Pee Dee Electric Cooperative',
        'Progress Energy Carolinas',
        'Santee Cooper',
        'South Carolina Electric & Gas Company',
        'Other',
    ],
    SD: [
        'Black Hills Power',
        'East River Electric Cooperative',
        'Montana-Dakota Utilities',
        'Northwestern Energy',
        'Otter Tail Power Company',
        'Rushmore Electric Cooperative',
        'West River Electric Association',
        'Xcel Energy',
        'Other',
    ],
    TN: [
        'Alcoa Electric Department',
        'Athens Utilities Board',
        'Bill Dower’s Sons Electric Co',
        'Chickasaw Electric Co-Op',
        'Citizens Utilities Board',
        'City of Lebanon Utilities',
        'Clarksville Department of Electricity',
        'Clinton Utilities Board',
        'Columbia Power and Water Systems',
        'Cumberland Electric Membership Corporation',
        'Dickson Electric System',
        'Duck River EMC',
        'Electric Power Board',
        'Elizabethton Electric Department',
        'Forked Deer Electric',
        'Fort Loudoun Electric Cooperative',
        'Gibson Electric Membership Corporation',
        'Jackson',
        'Kingsport Power (Appalachian Power)',
        'Knoxville Utilities Board',
        'Landon Electric Company',
        'Lawrenceburg Utility Department',
        'Lenoir City Utilities Board',
        'McMinnville Electric System',
        'Memphis Light Gas and Water',
        'Middle Tennessee Electric Membership Corporation (MTEMC)',
        'Mountain Electric Co-Op',
        'Nashville Electric Service',
        'Rains Electric Company',
        'Sequachee Valley Electric Cooperative',
        'Sevier County Electric System',
        'Sewell Electric Company',
        'Tennessee Jackson Energy Authority',
        'Tennessee Valley Authority',
        'Upper Cumberland Electric Membership Corporation (UCEMC)',
        'Volunteer Electric Cooperative',
        'Weakley County Municipal Electric System',
        'Other',
    ],
    TX: [
        'American Electric Power',
        'CenterPoint Energy',
        'Oncor Electric (Formerly TXU)',
        '4 Change',
        'Acacia',
        'Ambit',
        'American Light and Power',
        'Amigo Energy',
        'AP G&E',
        'Apna',
        'Austin Energy',
        'Bandera Electric Cooperative',
        'Bartlett Electric Cooperative',
        'Blackstone Electric Co',
        'Bluebonnet Electric Cooperative',
        'Bounce Energy',
        'Bowie Cass Electric Co-op',
        'Breeze',
        'Brilliant',
        'Brownsville Public Utilities Board',
        'Bryan Texas Utilities',
        'Central Texas Electric Co-Op',
        'Champion Energy Services',
        'Cherokee County Electric Cooperative (CCECA)',
        'Cirro',
        'City of Austin Utilities',
        'City of Coleman Utilities',
        'City of Gastonia Electric',
        'City of Sanger Utilities',
        'Clear View',
        'College Station Utilities',
        'Comanche Electric Cooperative',
        'Coserv Electric',
        'CPS Energy',
        'Deep East Texas Electric Coop',
        'Denton Municipal Eletric',
        'Direct Energy',
        'dPi Energy',
        'Eagle',
        'Eagle Energy',
        'El Paso Electric',
        'Electric Database Publishing',
        'Energy Plus Company',
        'Entergy',
        'Entrust Energy',
        'Everything',
        'Fannin County Electric Co',
        'Farmers Electrical Cooperative',
        'First Choice',
        'First Texas Energy Corporation',
        'Floresville Electric Light and Power System',
        'Frontier',
        'Garland Power and Light',
        'Georgetown Utility Services (GUS)',
        'GEUS Electric',
        'Gexa Energy',
        'Green Mountain Energy',
        'Guadalupe Valley Electric Cooperative',
        'GVEC',
        'Hamilton County Electric Cooperative',
        'Hilco Electric Co-Op',
        'Hino',
        'Houston County Electric Cooperative',
        'IGS energy',
        'infintite',
        'International Electric Power (IEP)',
        'Jackson EMC',
        'Jasper-Newton Electric Cooperative',
        'Just Energy',
        'Karnes Electric Co-Op',
        'Kerrville Public Utility Board (KPUB)',
        'Kona',
        'Lea County Electric Cooperative',
        'Limunant',
        'Lower Colorado River Authority',
        'Lubbock Power and Light',
        'Luminant',
        'Lyntegar Electric',
        'Magic Valley',
        'McCaffety Electric',
        'Medina Electric Cooperative',
        'Mid-South Synergy',
        'Mission Power',
        'Navarro County Electric Cooperative',
        'Navasota Valley Electric Cooperative',
        'New Braunfels Utilities',
        'New Leaf',
        'Nueces Electric Cooperative',
        'Our energy',
        'Panola-Harrison Electric Cooperative',
        'Pedernales Electric Cooperative',
        'Penny wise',
        'Reach',
        'Reliant Energy',
        'Rio Grande Electric Cooperative',
        'Rusk County Electric Cooperative',
        'Sam Houston Electric',
        'San Bernard Electric Co-Op',
        'San Patricio Electric Co-Op',
        'Sharyland Utilities',
        'Source',
        'South Plains Electric Cooperative',
        'Southwest Power and Light',
        'Southwestern Electric Power Company (SWEPCO)',
        'Spark Energy',
        'Star Tex Power',
        'Star Trex',
        'Stream Energy',
        'Summer',
        'Tara Energy',
        'Taylor Electric Co-Op',
        'Texas Electric Service Company',
        'Texpo',
        'Tri Eagle Energy',
        'Trinity Valley Electric Cooperative',
        'Tru Smart',
        'True Electric',
        'TXU',
        'United Cooperative Services',
        'Upshur Rural Electric Co-Op',
        'V247 Power Corportion',
        'Veteran',
        'Victoria Electric Cooperative',
        'Wise Electric Co-Op',
        'Wood County Electric Cooperative',
        'WTU Retail Energy',
        'XOOM Energy',
        'YEP',
        'Other',
    ],
    UT: [
        'Brigham City Public Power',
        'City of Santa Clara Power',
        'Dixie Escalante Electric',
        'Garkane Energy Cooperative',
        'Heber Light and Power',
        'Hurricane City Utilities',
        'Hyrum City Power & Light',
        'Intermountain Power Agency',
        'Kaysville City',
        'Lehi City Power',
        'Moon Lake Electric Association',
        'Murray City Power',
        'PacifiCorp (Rocky Mountain Power)',
        'Provo City Power',
        'Strawberry Electric Service District',
        'Other',
    ],
    VA: [
        'A and N Electric Cooperative',
        'Allegheny Power',
        'Appalachian Power',
        'Bristol Virginia Utilities',
        'Central Virginia Electric Cooperative',
        'Community Electric Cooperative',
        'Craig-Botetourt Electric Cooperative',
        'Danville Utilities',
        'Delmarva Power',
        'Dominion Virginia Power',
        'Franklin Electric Department',
        'IGS Energy',
        'Mecklenburg Electric Cooperative',
        'Northern Neck Electric Cooperative',
        'NOVEC',
        'Piedmont EMC',
        'Prince George Electric Cooperative',
        'Radford Electric Department',
        'Rappahannock Electric Cooperative',
        'Shenandoah Valley Electric Cooperative',
        'Southside Electric Cooperative',
        'Town of Richlands Electric Department',
        'Town of Vienna',
        'Wakefield Municipal Gas and Lighting',
        'Other',
    ],
    VT: [
        'Central Vermont Public Service',
        'Green Mountain Power',
        'Hardwick Electric Department',
        'Vermont Electric Cooperative',
        'Washington Electric Cooperative',
        'Other',
    ],
    WA: [
        'Avista Utilities',
        'Benton PUD',
        'Chelan County Public Utility District',
        'City of Blaine',
        'Columbia REA',
        'Cowlitz PUD',
        'Douglas County Public Utility District',
        'Grant County Public Utility District',
        'Klickitat Public Utility District',
        'Lewis County Public Works',
        'Mason County Public Utility District 3',
        'Modern Electric Water Company',
        'Orcas Power and Light Cooperative (OPALCO)',
        'Pacific County Public Utility District No. 2',
        'PacifiCorp (Pacific Power)',
        'Peninsula Light Company',
        'Puget Sound Energy',
        'Seattle City Light',
        'Snohomish County Public Utility District (PUD)',
        'Tacoma Power',
        'Tanner Electric',
        'Other',
    ],
    WI: [
        'Alliant Energy',
        'Dairyland Power Co-op',
        'Eau Claire Energy Cooperative',
        'Kaukauna Utilities',
        'Madison Gas and Electric',
        'Pierce Pepin Cooperative Services',
        'Polk-Burnett Electric Cooperative',
        'Richland Electric Cooperative',
        'River Falls Municipal Utility',
        'Superior Water Light and Power',
        'Village of Hustisford Electric',
        'We Energies',
        'Wisconsin Power and Light Company',
        'Wisconsin Public Service Corporation',
        'Xcel Energy',
        'Other',
    ],
    WV: [
        'Allegheny Power',
        'Appalachian Power',
        'Wheeling Electric Power (AEP Ohio)',
        'Other',
    ],
    WY: [
        'Cheyenne Light Fuel & Power',
        'High Plains Power',
        'High West Energy',
        'Lower Valley Energy',
        'PacifiCorp (Rocky Mountain Power)',
        'Wheatland Rural Electric Association',
        'Other',
    ],
};

let shadowRoot, stateInput, stateSelectInput, providerInput, providerList;
let oldAttributeValue = 'false';
const config = {
    attributes: true
};
let hasCalculatedSessionLength = false;
let startTime = Date.now();

// Utility Functions
const getUserAgent = () => window.navigator.userAgent;

function delay (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const findKeyByValue = (obj, value) => {
    const entry = Object.entries(obj).find(([key, val]) => val === value);
    return entry ? entry[0]: null; // Return the key or null if not found
};

const createOption = (value, text) => {
    const option = document.createElement('option');
    option.value = value;
    option.text = text;
    return option;
};

const clearOptions = (selectElement, placeholderText) => {
    selectElement.innerHTML = ''; // Clear existing options
    selectElement.appendChild(createOption('', placeholderText)); // Add placeholder option
};

const updateStateSelect = (stateSelect) => {
    Object.entries(states).forEach(([key, value]) => {
        stateSelect.appendChild(createOption(key, value)); // Add options to the select box
    });
};

const updateElecProviders = (state, elecProviderSelect) => {
    clearOptions(elecProviderSelect, 'Electricity Supplier');
    const availableProviders = stateElectricProviders[state] || [];
    availableProviders.forEach(provider => {
        elecProviderSelect.appendChild(createOption(provider, provider));
    });
};

const calculateSessionLength = (sessionLengthInput) => {
    const startTimeInput = shadowRoot.querySelector('[data-variable="startTime"]');
    const endTimeInput = shadowRoot.querySelector('[data-variable="endTime"]');
    const sessionLength = Math.floor((Date.now() - startTime) / 1000);
    sessionLengthInput.value = `${sessionLength}`;
    startTimeInput.value = startTime;
    endTimeInput.value = Date.now();
};

const fetchIpAddress = async (ipAddressInput) => {
    try {
        const response = await fetch('https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp');
        const data = await response.json();
        if (data.ip) {
            document.querySelector(`[data-variable="${ipAddressInput}"]`).value = data.ip;
        }
    } catch (error) {
        console.error('Error fetching IP address:', error);
    }
};

// Observer Callback
const observerCallback = async (mutationList) => {
    mutationList.forEach(mutation => {
        if (mutation.type === 'childList') {
            const selectedState = stateSelectInput.value;
            if (selectedState) {
                const stateAbbr = findKeyByValue(states, selectedState);
                updateElecProviders(stateAbbr || selectedState, providerInput);
            }
        }
    });
};

// Event Listeners
const setupEventListeners = () => {
    stateInput.addEventListener('change',
        () => {
            const selectedState = stateInput.value;
            if (selectedState) {
                const stateAbbr = findKeyByValue(states, selectedState);
                updateElecProviders(stateAbbr || selectedState, providerInput);
            }
        });
};

// Initialization
const initializeInputs = async () => {
    stateInput = shadowRoot.querySelector('[data-variable="state_input"]');
    providerInput = shadowRoot.querySelector('[data-variable="elec_provider"]');
    const urlInput = shadowRoot.querySelector('[data-variable="original_url"]');
    const userAgent = shadowRoot.querySelector('[data-variable="user_agent"]');

    updateStateSelect(stateInput);
    setupEventListeners();

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const stateValue = params.get('state')?.toUpperCase();

    if (stateValue) {
        stateInput.value = stateValue;
        stateInput.dispatchEvent(new Event('change', {
            bubbles: true
        }));
    }

    urlInput.value = `${url.origin}${url.pathname}`;
    userAgent.value = getUserAgent();
    fetchIpAddress('ip_address');
};

// Main Event Listener for heyflow-init
window.addEventListener('heyflow-init', async () => {
    try {
        console.info('Heyflow Initialising');
        shadowRoot = document.querySelector('heyflow-wrapper')?.shadowRoot || document;
        await initializeInputs();
        const jornayaInput = shadowRoot.querySelector('[data-variable="jornaya_id"]');

        await delay(20000);
        if (window.LeadiD.token) {
            jornayaInput.value = window.LeadiD.token;
        }
    } catch (error) {
        console.error(error);
    }
});

window.addEventListener('heyflow-change', event => {
    const sessionLengthInput = shadowRoot.querySelector('[data-variable="session_length"]');
    const allFieldData = event.detail.fieldsSimple;
    
    if (allFieldData.hasOwnProperty(lastInputVariable)) {
        if (!hasCalculatedSessionLength) {
            calculateSessionLength(sessionLengthInput);
            hasCalculatedSessionLength = true;
        }
    }
});